import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import picture404 from '../assets/images/picture404.jpg';
import { TextLink as RawTextLink } from '../ui';
import { useTranslation } from 'react-i18next';
import '../layout.css';

const Image = styled.div`
  position: relative;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0.45) 70%
  ), url('${picture404}');

  height: 100vh;
`;

const TextBlock = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  text-align: center;
  align-items: center;
  width: 100%;

  color: #fff;
  margin-right: auto;
  margin-left: auto;
`;

const MainTitle = styled.div`
  font-size: 80px;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled.div`
  font-size: 35px;
  font-weight: 600;
`;

const Text = styled.div`
  font-size: 24px;
`;

const TextLink = styled(RawTextLink)`
  font-size: 28px;
  font-weight: 600;
  margin-left: ${({ theme }) => theme.spacing(0.5)};

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
`;

const LinkLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Image />
      <TextBlock>
        <div>
          <MainTitle>{t('404.mainTitle')}</MainTitle>
          <Title>{t('404.title1')}</Title>
          <Title>{t('404.title2')}</Title>
          <LinkLine>
            <Text>{t('404.text')}</Text>
            <TextLink as={Link} to="/">
              {t('404.link')}
            </TextLink>
          </LinkLine>
        </div>
      </TextBlock>
    </>
  );
};

export default NotFoundPage;
